<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img width="100px" src="/mela2.svg" alt="" />
                </div>
                <p class="card-text mb-25">
                  {{ office_data.offices_adress }}
                </p>
                <p class="card-text mb-25">
                  {{ office_data.cities_name }}, {{ office_data.states_name }},
                  {{ office_data.countries_name }}
                </p>
                <p class="card-text mb-0"></p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number"
                    >#{{ invoiceData.data[0].id }}</span
                  >
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Date Issued:</p>
                  <p class="invoice-date">
                    {{ invoiceData.data[0].created }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.data[0].customer"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Invoice To:</h6>
                <h6 class="mb-25">
                  {{ invoiceData.data[0].customer.first_name }}
                  {{ invoiceData.data[0].customer.last_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.data[0].customer.city.name }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.data[0].customer.mail }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Payment Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Total Due:</td>
                        <td>
                          <span class="font-weight-bold">
                            $
                            {{ total_invoice }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->

          <b-table-lite
            v-if="invoiceDescriptionProduct.length != 0"
            responsive
            :items="invoiceDescriptionProduct"
            :fields="['product', 'cost', 'qty', 'price']"
          >
            <template #cell(product)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product }}
              </b-card-text>
            </template>
          </b-table-lite>

          <b-table-lite
            v-if="invoiceDescriptionService.length != 0"
            responsive
            :items="invoiceDescriptionService"
            :fields="['service', 'cost', 'qty', 'price']"
          >
            <template #cell(taskDescription)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">${{ subtotal_order }}</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Discount:</p>
                    <p class="invoice-total-amount">-${{ discount_order }}</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Tips:</p>
                    <p class="invoice-total-amount">${{ tips_order }}</p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">${{ total_invoice }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "../invoiceStoreModule";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
  },
  setup() {
    const invoiceData = ref(null);
    const paymentDetails = ref({});

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescriptionProduct = ref([]);
    const invoiceDescriptionService = ref([]);

    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let total_invoice = ref(0);
    let total_order = ref(0);
    let subtotal_order = ref(0);
    let discount_order = ref(0);
    let tips_order = ref(0);
    let office_data = ref({});

    store
      .dispatch("app-invoice/fetchInvoice", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data } = response.data;
        invoiceData.value = response.data;

        console.log("Que da traer", data[0].discount_order);
        office_data.value = JSON.parse(
          localStorage.getItem("userData")
        ).dataUser;
        total_order.value = Number(data[0].total_order);
        tips_order.value = Number(data[0].total_tips);
        subtotal_order.value = Number(data[0].subtotal_order);
        discount_order.value = Number(data[0].discount_order);
        total_invoice.value = total_order.value + tips_order.value;
        total_invoice.value = total_invoice.value.toFixed(2);
        const arrayProducts = [];
        const arrayService = [];

        data.map((data, index) => {
          data.detailProduct.map((item, key) => {
            arrayProducts.push({
              product: item.product.name,
              category: item.category.name,
              cost: item.price,
              qty: item.qty,
              price: item.price * item.qty,
              salesPerson: `${item.employee.first_name} ${item.employee.last_name}`,
              "%comission": item.percentage_comission,
              $commision:
                (item.price * item.qty * item.percentage_comission) / 100,
            });
          });

          data.detailService.map((item, key) => {
            arrayService.push({
              service: item.service.name,
              category: "",
              cost: item.price,
              qty: item.qty,
              price: item.price * item.qty,
              salesPerson: "",
              "%comission": item.percentage_comission,
              $commision:
                (item.price * item.qty * item.percentage_comission) / 100,
            });
          });
        });

        invoiceDescriptionProduct.value = arrayProducts;
        invoiceDescriptionService.value = arrayService;
        /* paymentDetails.value = response.data.paymentDetails; */
      })
      .catch((error) => {
        if (error.response.status === 404) {
          invoiceData.value = undefined;
        }
      });

    const printInvoice = () => {
      window.print();
    };

    return {
      invoiceData,
      paymentDetails,
      invoiceDescriptionProduct,
      invoiceDescriptionService,
      printInvoice,
      total_invoice,
      office_data,
      total_order,
      tips_order,
      subtotal_order,
      discount_order,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
